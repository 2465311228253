import React, {Component, lazy, Suspense} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import moment from "moment";
import {Loader} from '../modules/base_components';
import {NotFoundPage, TestPage, SentryErrorGeneratorPage} from '../modules/static_pages';

import {CookieBanner, LoggedInRoute, Menu, PushNotification} from '../modules/custom_components';
import {ErrorBoundary} from "../modules/base_components";

import {refreshAuth, resetNavigationRedirect} from "./actions";

import urls from '../values/urls';
import {IS_DEV_MODE, IS_ROUTER_ENV} from "../utils/utils";
// import css
import "video-react/dist/video-react.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../static/css/vendor/bootstrap/bootstrap.min.css";
import "../../static/css/style.css";

// dynamic import with code splitting
const LoginRoute = lazy(() => import('../routes/login'));
const DashboardRoute = lazy(() => import('../routes/dashboard'));
const SensorsRoute = lazy(() => import('../routes/sensors_dashboard'));
const SensorsDahsboardDetailRoute = lazy(() => import('../routes/sensors_dashboard_detail'));
const PlantDetailRoute = lazy(() => import('../routes/plant_detail'));
const SensorDetailRoute = lazy(() => import('../routes/sensor_detail'));
const CameraFeedRoute = lazy(() => import('../routes/camera_feed'));
const ImagesRoute = lazy(() => import('../routes/images'));
const SettingsRoute = lazy(() => import('../routes/settings'));
const AlarmsRoute = lazy(() => import('../routes/alarms'));
const NotificationListRoute = lazy(() => import('../routes/notifications'));
const NotificationDetailRoute = lazy(() => import('../routes/notification_detail'));
const ConfigurationDashboardRoute = lazy(() => import('../routes/configuration_dashboard'));
const ConfigurationRoute = lazy(() => import('../routes/configuration'));
const ConfigurationDetailRoute = lazy(() => import('../routes/configuration_detail'));


moment.locale('it');
const LoadingComponent = <Loader loading/>;

export class Index extends Component {
    componentDidMount() {
        if (this.props.user.user) {
            this.props.refreshAuth();
        }
    }

    renderDevTestPage() {
        return IS_DEV_MODE
            ? (
                <Route path={urls.DEV_TEST} exact component={props => <TestPage {...props}/>}/>
            )
            : null;
    }

    renderSentryPage() {
        return (
            <Route path={'/sentry-error-generator'} exact component={props => <SentryErrorGeneratorPage {...props}/>}/>
        );
    }

    renderWhatsUp() {
        return (
            <a id="whats-up-icon" href="https://wa.me/3899080260?text=Hello%21%20We%20need%20assistance%2e" className="whatsup_class flaot container" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-whatsapp whatsup_icon my_float"></i> 
            </a>
        );
    }

    checkUserPermissions(permission) {
        const {user} = this.props;
        let hasPerm = true;

        if (!user.user) {
            hasPerm = false;
        } else if (user.user.permissions && !user.user.is_staff) {
            hasPerm = user.user.permissions.includes(permission);
        }
        return hasPerm;
    }

    render() {
        return (
            <ErrorBoundary>
                <BrowserRouter>
                    <div className="app-wrapper" style={_style.container}>
                        <Loader loading={this.props.user.loading}/>

                        {
                            !IS_ROUTER_ENV
                                ? <PushNotification/>
                                : null
                        }

                        <Menu/>

                        <Suspense fallback={LoadingComponent}>
                            <Switch>
                                <Redirect exact from='/' to={urls.DASHBOARD_BASE}/>
                                <Redirect exact from='/dashboard' to={urls.DASHBOARD_BASE}/>
                                <Route path={urls.LOGIN} exact component={props => <LoginRoute {...props}/>}/>

                                {/*------------- training ---------------------------------------*/}
                                <LoggedInRoute path={`${urls.DASHBOARD_BASE}/sensors/:id`} exact
                                               component={props => <SensorDetailRoute {...props}/>}/>
                                <LoggedInRoute path={`${urls.DASHBOARD_BASE}/plant/:id`} exact
                                                component={props => <PlantDetailRoute {...props}/>}/>

                                {/*------------- configuration ---------------------------------------*/}
                                {(this.checkUserPermissions("update_sensor_configuration") && !IS_ROUTER_ENV ) ?
                                    <LoggedInRoute path={urls.CONFIGURATION_BASE} exact
                                                    component={props => <ConfigurationDashboardRoute {...props}/>}/>
                                    : null
                                }
                                {(this.checkUserPermissions("update_sensor_configuration") && !IS_ROUTER_ENV ) ?
                                    <LoggedInRoute path={`${urls.CONFIGURATION_BASE}/sensors/:id`} exact
                                                    component={props => <ConfigurationRoute {...props}/>}/>
                                    : null
                                }
                                {(this.checkUserPermissions("update_sensor_configuration") && !IS_ROUTER_ENV ) ?
                                    <LoggedInRoute path={`${urls.CONFIGURATION_BASE}/plant/:id`} exact
                                                    component={props => <ConfigurationDetailRoute {...props}/>}/>
                                    : null
                                }

                                {/*------------- race dashboard(push button) --------------------------*/}
                                {( this.checkUserPermissions("has_remote_control")) ?
                                    <LoggedInRoute path={urls.SENSORS_BASE} exact
                                               component={props => <SensorsRoute {...props}/>}/>
                                    : null
                                }

                                {( this.checkUserPermissions("has_remote_control")) ?
                                    <LoggedInRoute path={`${urls.SENSORS_BASE}/plant/:id`} exact
                                                    component={props => <SensorsDahsboardDetailRoute {...props}/>}/>
                                    : null
                                }

                                {/*------------- sensor commands ---------------------------------------*/}
                                <LoggedInRoute exact path={`${urls.DASHBOARD_BASE}/sensors/camera-feed/:id`}
                                                component={props => <CameraFeedRoute {...props}/>}/>
                                <LoggedInRoute path={urls.DASHBOARD_BASE} exact
                                               component={props => <DashboardRoute {...props}/>}/>
                                <LoggedInRoute path={urls.ALARMS_BASE} exact
                                               component={props => <AlarmsRoute {...props}/>}/>
                                <LoggedInRoute path={urls.IMAGES_BASE} exact
                                               component={props => <ImagesRoute {...props}/>}/>

                                {
                                    !IS_ROUTER_ENV
                                        ? <LoggedInRoute path={`${urls.NOTIFICATIONS_BASE}/detail/:id`} exact
                                                         component={props => <NotificationDetailRoute {...props}/>}/>
                                        : null
                                }

                                {
                                    !IS_ROUTER_ENV
                                        ? <LoggedInRoute path={urls.NOTIFICATIONS_BASE} exact
                                                         component={props => <NotificationListRoute {...props}/>}/>
                                        : null
                                }

                                {
                                    !IS_ROUTER_ENV
                                        ? <LoggedInRoute path={urls.SETTINGS_BASE} exact
                                                         component={props => <SettingsRoute {...props}/>}/>
                                        : null
                                }

                                {this.renderDevTestPage()}
                                {this.renderSentryPage()}
                                <Route component={props => <NotFoundPage {...props}/>}/>
                            </Switch>
                        </Suspense>
                        {this.renderWhatsUp()}
                        <CookieBanner/>
                    </div>
                </BrowserRouter>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = ({navigation, user}) => {
    return {navigation, user};
};

export default connect(mapStateToProps, {
    resetNavigationRedirect,
    refreshAuth,
})(Index);

const _style = {
    container: {
        height: '100%',
    },
};
